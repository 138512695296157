<template>
    <div class="train_list train_public" v-loading="loading" element-loading-text="拼命加载中">
        <header>
            <ul class="tab">
                <li class="tabs flex">
                    <div class="radios relative">
                        <div class="color66 span_name paddT5">培训类别：</div>
                        <el-radio-group v-model="page.projectType" class="world">
                            <ul class="top flex-wrap">
                                <li class="">
                                    <el-radio label="">全部</el-radio>
                                </li>
                                <li v-for="(n,i) in publicTab" :key="i" v-if="i < 9">
                                    <el-radio :label="n.value">{{n.label}}</el-radio>
                                </li>
                            </ul>
                        </el-radio-group>
                    </div>
                </li>
                <li class="tabs flex" v-if="false">
                    <div class="radios relative">
                        <div class="color66 span_name">课程类型：</div>
                        <el-radio-group v-model="page.radio1" class="world">
                            <ul class="top flex">
                                <li class="">
                                    <el-radio :label="0">全部</el-radio>
                                </li>
                                <li>
                                    <el-radio :label="1">正在直播</el-radio>
                                </li>
                            </ul>
                        </el-radio-group>
                    </div>
                    <div class="right">
                        <div><img src="@/static/train/new.png" alt="" class="img marR5">最新</div>
                        <div><img src="@/static/train/hot.png" alt="" class="img marR5">最多人学</div>
                    </div>
                </li>
            </ul>
        </header>
        <section>
            <div class="breadcrumb">
                <span class="name">当前位置：</span>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/training/' }">职业培训</el-breadcrumb-item>
                    <el-breadcrumb-item>公开课</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <ul class="list public flex flex-wrap">
                <li v-for="(n,i) in publicList" :key="i" class="box" @click="clicks(n.id)">
                    <router-link :to="'./publicDetail?id='+n.id" target="_blank" :title="n.trainName" class="name">
                        <div class="top relative">
                            <img :src="n.imgurl" alt="" class="banner err_image">
                        </div>
                        <div class="bottom marT10">
                            {{n.trainName}}
                            <div class="address">
                                <ul>
                                    <li>
                                        <span>共{{n.reamark}}章节</span>
                                    </li>
                                    <!-- <li>{{n.studys}}人观看</li> -->
                                </ul>
                            </div>
                        </div>
                    </router-link>

                </li>
            </ul>
            <status :type="status.type" v-if="status.isHide"></status>
        </section>
        <div class="pages">
            <el-pagination
                    v-if="page.total"
                    background
                    class="work_pagination"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="page.pageNum"
                    :page-size="page.pageSize"
                    prev-text="上一页"
                    next-text="下一页"
                    layout="prev, pager, next, total, jumper"
                    :total="page.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import selectCity from "@/components/search/selectCity";
    import status from "@/components/public/status";
    export default {
        name: "public",
        components: {
            selectCity,
            status
        },
        data() {
            return {
                form: {
                    radio: 0,
                    radio1: 0,
                },
                publicList: [],
                publicTab: [],
                page: {
                    total: 0,
                    pageNum: 1,
                    pageSize: 12,
                    projectType: '',
                    videoType:"03"
                },
                loading: false,
                status:{
                  isHide:false,
                  type:'',
                },
            }
        },
        watch: {
            page: {
                handler(newName, oldName) {
                    this.publicApi();
                },
                deep: true
            }
        },
        methods: {
            async publicApi(val) {
                try {
                    let res = await this.$api.publicListApi(this.page);
                    this.loading = false;
                    this.publicList = res.data.data.records;
                    this.page.total = res.data.data.total;
                    if (this.publicList.length <= 0) {
                        this.status.isHide = true;
                        this.status.type = 'result';
                    }else{
                        this.status.isHide = false;
                    }
                } catch (e) {
                    this.loading = false;
                    this.status.isHide = true;
                    this.status.type = 'wifi';
                }
            },
            //公开课筛选项
            publicSelectList() {
                this.$api.selectListApi({dictCode: 'open_train_class'}).then(res => {
                    if (res.data.success) {
                        this.publicTab = res.data.data;
                    }
                })
            },
            //点击量
            clicks(id){
                this.$api.publicClickApi({id:id}).then(res =>{})
            },
            //分页
            handleCurrentChange(val) {
                this.page.pageNum = val;
                this.publicApi();
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
        },
        created() {
            this.publicApi();
            this.publicSelectList();
          //判断是否移动端
          this.$mobile('https://lzmz.cdpee.org.cn/#/pages/train/public/index');
        },
        mounted() {
            this.$emit('goRouter', '/training/')
        }
    }
</script>

<style scoped lang="less">
    @import "~assets/css/train.less";
</style>
